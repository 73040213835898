<template>
  <div class="spop-lightbox" v-if="jpop && show" :class="{ hiding: hiding }" @click="handleContinueClick">
    <div class="site-popup" @click.stop>
      <close-button small @click="handleContinueClick"></close-button>
      <div class="spop-left">
        <template v-if="jpop.image">
          <div class="spop-image">
            <img :src="jpop.image">
          </div>
        </template>
        <div class="jpop-title" v-html="jpop.title"></div>
      </div>
      <div class="spop-right">
        <div class="jpop-right-top">
          <div class="jpop-text" v-html="jpop.text"></div>
        </div>
        <div class="jpop-cont" v-html="jpop.cont_text" @click="handleContinueClick"></div>
        <div class="jpop-pls" v-html="jpop.pls_text" @click="handleBlockClick"></div>
      </div>
    </div>
  </div>
</template>
<script>

import CloseButton from './nav/CloseButtton.vue'
import { waitfor } from '../utils.js'

export default {
  components: {
    CloseButton
  },
  props: {
    jpop: Object
  },
  data () {
    return {
      show: true,
      hiding: true
    }
  },
  methods: {
    async showWindow () {
      this.$nextTick(async () => {
        await waitfor(400)
        this.hiding = false
      })
      
    },
    async handleContinueClick() {
      this.hideWindow()
      await this.$post('LogPopupView', { block: false})
    },
    async handleBlockClick () {
      this.hideWindow()
      await this.$post('LogPopupView', { block: true })
    },
    async hideWindow () {
      this.hiding = true
      await waitfor(500)
      this.show = false
    }
  },
  watch: {
    jpop () {
      if(this.jpop) {
        this.showWindow()
      }
    }
  }
}
</script>
<style>
.spop-lightbox {
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.6);
  z-index:999;
  transition: all 0.3s ease-in-out;
  opacity:1;
  cursor:pointer;
}

.spop-lightbox.hiding {
  opacity: 0;
}



.site-popup {
  position: absolute;
  cursor:auto;
  background-color:#fff;
  border-radius:10px;
  z-index:1000;
  width:500px;
  min-height:332px;
  max-height:95vh;
  display:grid;
  overflow:hidden;
  grid-template-columns: 1fr 1fr;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  transition: all 0.3s ease-in-out;
}

.spop-lightbox.hiding .site-popup {
  transform: translate(-50%,-50%) scale(0.1);
}

.spop-left {
  position:relative;
  background-color:#fdd05d;
  color:#fff;
  box-sizing:border-box;
}

.spop-image {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

}

.jpop-title {
  position:absolute;
  top:0;
  width:100%;
  text-align:center;
  font-size:24px;
  box-sizing: border-box;
  margin:30% 0;
  padding:20px;
  font-weight:bold;
  line-height:1.2em; 
}

.spop-image img{
  display:block;
  object-fit:cover;
  width:100%;
  height:100%;
}

.jpop-text {
  font-size: 16px;
  margin:0 16px;
  line-height: 1.3em;
}

.spop-right {
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  text-align:center;
}

.jpop-right-top {
  display:flex;
  flex-direction: column;
  justify-content: center;
  flex-grow:1;
  
}

.popup-button {
  text-align: center;
  background-color: var(--ccw-hilite);
  padding: 8px;
  margin: 20px 0;
  cursor: pointer;
  transition: all 0.1s linear;
  color:#fff;
  display:inline-block;
  padding:0.5em 1em;
  border-radius:5px;
}

.popup-button a {
  color:#fff;
  text-decoration: none;
}

.popup-button a:hover {
  text-decoration: none;
}
.popup-button:hover {
  background-color:#000;
}

.jpop-cont {
  padding:10px;
}

.jpop-pls {
  padding: 10px;
  font-size:12px;
}

.jpop-cont, .jpop-pls {
  color:var(--ccw-hilite);
  cursor:pointer;
}

.jpop-cont:hover, .jpop-pls:hover{
  text-decoration: underline;
  color:#000;
}



</style>