<template>
  <a :href="url" @click.prevent class="text-hiliter"><span class="search-text-hiliter" v-html="mcontent" @keyup.stop.prevent="handleKeyup"></span></a>
</template>

<script>
export default {
  props: {
    value: String,
    search: String,
    url: String
  },
  computed: {
    mcontent () {
      if (!this.value) {
        return ''
      }
      const rg = new RegExp(this.search, 'ig')
      const ret = this.value.replace(rg, (match, p1) => {
        return `<mark>${match}</mark>`
      })
      return ret
    }
  },
  methods: {
    handleKeyup (evt) {
      this.$emit('keyup', evt)
    }
  }

}
</script>

<style>
.search-text-hiliter mark{
  background-color:rgba(202,206,250,0.5);
  white-space: nowrap;
}

.selected .search-text-hiliter mark{
  color:#fff;
}

a.text-hiliter {
  color:#000;
  text-decoration: none;
}

.selected a.text-hiliter{
  color:#fff;
}

.selected:hover a.text-hiliter{
  color:#000;
}

.selected:hover .search-text-hiliter mark{
  color:#000;
}
</style>
