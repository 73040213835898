<template>
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.68 17.66">
    <g id="Layer_1-2">
      <g>
        <path class="bf-4"
          d="M9.07,.06H.77C.38,.06,.06,.38,.06,.77V16.89c0,.39,.32,.71,.71,.71H11.91c.39,0,.71-.32,.71-.71V3.61L9.07,.06Z" />
        <g>
          <path class="bf-1" d="M10.5,3.51c-.75,0-1.36-.61-1.36-1.36V.3l3.2,3.2h-1.85Z" />
          <path class="bf-3"
            d="M9.24,.55l2.85,2.85h-1.6c-.69,0-1.26-.56-1.26-1.26V.55m-.21-.5V2.15c0,.8,.66,1.46,1.46,1.46h2.09L9.03,.06h0Z" />
        </g>
        <g>
          <line class="bf-2" x1="1.96" y1="7.85" x2="10.72" y2="7.85" />
          <line class="bf-2" x1="1.96" y1="10.1" x2="10.72" y2="10.1" />
          <line class="bf-2" x1="1.96" y1="12.35" x2="10.72" y2="12.35" />
        </g>
      </g>
    </g>
  </svg>
</template>
<style>
  .bf-1 {
    fill: #fff;
  }

  .bf-2 {
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: .95px;
  }

  .bf-2,
  .bf-3,
  .bf-4 {
    fill: currentColor;
  }

  .bf-4 {
    stroke: currentColor;
    stroke-miterlimit: 10;
    stroke-width: .11px;
  }
</style>