<template>
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.29 17.77">
    <g id="Layer_1-2">
      <g>
        <circle class="usr-1" cx="6.65" cy="4.41" r="3.94" />
        <path class="usr-2"
          d="M12.98,17.45v-3.99c0-1.93-1.58-3.52-3.52-3.52H3.83c-1.93,0-3.52,1.58-3.52,3.52v3.99H12.98Z" />
      </g>
    </g>
  </svg>
</template>
<style>
  .usr-1 {
    stroke-width: .94px;
  }

  .usr-1,
  .usr-2 {
    fill: currentColor;
    stroke: currentColor;
    stroke-miterlimit: 10;
  }

  .usr-2 {
    stroke-width: .63px;
  }
</style>