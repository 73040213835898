<template>
  <div
    class="tiny-button"
    :style="{ color: color }"
    :class="{ disabled: disabled, selected: selected, big: big, loading: loading, round: round, solid: solid }"
    @click.stop.prevent="handleClick"
  >
    <div class="tb-text">
      <slot></slot>
    </div>
    <div class="pending">
      <pending-icon></pending-icon>
    </div>
  </div>
</template>
<script>
import PendingIcon from '@/components/icons/HourglassIcon.vue'
export default {
  components: {
    PendingIcon
  },
  props: {
    color: String,
    disabled: Boolean,
    selected: Boolean,
    big: Boolean,
    to: String,
    href: String,
    loading: Boolean,
    round: Boolean,
    solid: Boolean
  },
  emits: ['click'],
  methods: {
    handleClick(e) {
      e.stopPropagation()
      if (!this.disabled) {
        if (this.href) {
          window.location = this.href
          return
        }
        if (this.to) {
          this.$router.push(this.to)
          return
        }
        this.$emit('click')
      }
    }
  }
}
</script>
<style scoped>
.tiny-button {
  border: 1px solid currentColor;
  display: inline-block;
  font-size: 12px;
  padding: 2px 6px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s linear;
  user-select: none;
  position: relative;
}

.tiny-button.solid {
  background-color: currentColor;
}

.tiny-button.round {
  border-radius: 5px;
}

.tiny-button.big {
  font-size: 16px;
  padding: 6px 12px;
}

.tiny-button.disabled {
  cursor: default;
  opacity: 0.3;
}

.tiny-button:hover,
.tiny-button.selected {
  background-color: currentColor;
}

.tiny-button.disabled:hover {
  background-color: transparent;
}

.tiny-button:hover .tb-text,
.tiny-button.selected .tb-text,
.tiny-button.solid .tb-text {
  color: #fff;
}

.tiny-button.solid:hover {
  background-color: #000;
  border-color: #000;
}

.tiny-button.disabled:hover .tb-text {
  color: currentColor;
}

.pending {
  display: none;
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading .pending {
  display: block;
}

.tb-text {
  transition: all 0.3s linear;
  white-space: nowrap;
}

.tiny-button:hover .pending {
  color: #fff;
}

.loading .tb-text {
  opacity: 0;
}
</style>
