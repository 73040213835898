<template>
  <div class="ms-nav">
    <div class="ms-nav-content">
      <div class="ms-nav-top">
        <close-button @click="$emit('close')"></close-button>
        <div class="ms-title">
          <router-link to="/home">{{ $xtrans.webtitle }}</router-link>
        </div>
        <div class="ccw-ms-holder">
          <div v-for="ms in store.metasections" :key="ms.id" :class="{sel: isCurrent(ms)}">
            <router-link :to="ms.url" @click.native="handleNav">{{ ms.name }}</router-link>
          </div>
        </div>
        <div class="ms-divider"></div>
        <reward-panel />
      </div>
      <div class="ms-nav-botttom">
        <div class="admin-tools" v-if="store.member.Edit || store.member.Review">
          <div class="admin-link">
            <router-link to="/review-menu" @click.native="handleNav">Admin</router-link>
          </div>
        </div>
        
        <div class="footer-links">
          <a href="http://www.gettyimages.com/company/privacy-policy" target="_blank">{{ $xtrans.privacy }}</a>
          <a href="http://www.gettyimages.com/company/terms" target="_blank">{{ $xtrans.terms }}</a>
          <a href="http://www.gettyimages.com/company/editorial-policy" target="_blank">{{  $xtrans.policy }}</a>
          <router-link to="/notifications">{{ $xtrans.email_prefs }}</router-link>
          <div class="copyright">
            <span v-html="$xtrans.copyright"></span>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
</template>
<script>
import CloseButton from './CloseButtton.vue'
import RewardPanel from '../RewardPanel.vue'

// const mq = window.matchMedia('(max-width: 1024px)');

export default {
  inject: ['store'],
  components: {
    CloseButton,
    RewardPanel
  },
  methods: {
    isCurrent(metasection) {
      if(this.store.state.currentMetasection){
        return (this.store.state.currentMetasection.id === metasection.id)
      }
      return false
    },
    handleNav() {
      this.$emit('close')
    }
  }
}
</script>
<style>
  .ms-title {
    font-weight: 700;
    font-size:19px;
    
  }

  .ms-title a, .ms-title a:visited{
    color:var(--color-text);
  }

  .ms-title a:hover {
    color:var(--ccw-hilite);
    text-decoration: none;
  }

  .ms-nav {
    width: 0;
    position: fixed;
    padding:0;
    top:0;
    left:0;
    overflow:hidden;
    transition:width 0.3s ease-in-out;
  }

  .open .ms-nav{
    width: 300px;
  }

  .ms-nav-content{
    padding:20px;
    width:300px;
    min-height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .ms-nav-top {
    position: relative;
  }

  .ccw-ms-holder{
    margin-top:40px;
    line-height:32px;
    padding-bottom:32px;
    border-bottom:1px solid #ccc;
  }


  .ccw-ms-holder a{
    color:#000;
  }

  .ccw-ms-holder .sel a{
    font-weight: 700;
  }

  .ccw-ms-holder a:visited{
    color:#000;
  }

  .ccw-ms-holder a:hover{
    color:var(--ccw-hilite);
    text-decoration: none;
  }

  .ms-divider{
    margin-top:50px;
  }

  .admin-temp {
    font-size:12px;
    padding:10px 0;
    opacity:0.3;
    transition: opacity 0.2s linear;
  }

  .admin-temp:hover{
    opacity: 1;
  }

  .footer-links {
    font-size: 14px;
  }
  .footer-links a {
    display:block;
  }
  .footer-links .copyright {
    font-size: 14px;
    line-height: auto;
    margin-top:16px;
    color:#666;
  }
</style>