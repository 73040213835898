<template>
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.4 14.4">
    <g id="Layer_1-2">
      <path class="close-1"
        d="M7.89,7.2L14.25,.83c.19-.19,.19-.5,0-.69s-.5-.19-.69,0L7.2,6.51,.83,.14C.64-.05,.33-.05,.14,.14-.05,.33-.05,.64,.14,.83L6.51,7.2,.14,13.56c-.19,.19-.19,.5,0,.69s.5,.19,.69,0L7.2,7.89l6.36,6.36c.19,.19,.5,.19,.69,0,.19-.19,.19-.5,0-.69L7.89,7.2Z" />
    </g>
  </svg>
</template>
<style>
  .close-1 {
    fill: currentColor;
  }
</style>