<template>
  <div class="t-search-holder" @click.stop :class="{msfilter: msfilter }">
    <div class="t-search-area" :class="{ focused: focused }">
      <div class="search-glass" @click.stop="handleSearch"><icon-mag /></div>
      <div class="t-search-grower">
        <input type="text" :placeholder="pholder" v-model="msearch" @focus="handleOpen" ref="searchy" @blur="focused=false" 
             @keydown.enter.stop.prevent @keyup.enter.stop.prevent="handleSearch"
             @click="selectedItem = -1">
        <div class="search-clear" @click="handleClear" :class="{ viz: showClear }">X</div>
      </div>
      
      <div class="sa-filters" :class="{ open: filteropen }" v-if="!msfilter">
        <filter-component v-model="mode" @change="handleChangeMode"></filter-component>
      </div>
    </div>
    
    <div class="t-search-drop" :class="{viz: open && msearch.length > 1}">
      <div v-for="(result,index) in results" :key="result.Id" class="t-search-item" @click.stop.prevent="navigate(result)" :class="{selected: index === selectedItem }">
        <text-hiliter :value="result.Title" :url="massageUrl(result)" :search="msearch" @keyup="checkKey"></text-hiliter>
      </div>
    </div>
  </div>
</template>

<script>
import TextHiliter from './TextHiliter.vue'
import FilterComponent from './FilterComponent.vue'
import IconMag from '../icons/IconMag.vue'
import { post, gtag } from '../../utils.js'

export default {
  components: { TextHiliter, FilterComponent, IconMag },
  inject: ['store'],
  props: {
    msfilter: Boolean,
    metasection: Object
  },
  data () {
    return {
      msearch: '',
      results: [],
      open: false,
      focused: false,
      mode: '',
      tt: 0,
      selectedItem: -1,
      filteropen: false
    }
  },
  computed: {
    pholder () {
      if (this.mode === 'F') {
        return this.$xtrans.search_faqs
      } else if (this.mode === 'A') {
        return this.$xtrans.search_articles
      }
      
      return this.$xtrans.search_contrib
    },
    showClear () {
      return this.msearch.length > 0
    }
  },
  methods: {
    checkKey (evt) {
      if (evt.keyCode === 38 && this.selectedItem >= 0) {
        this.selectedItem--
        this.scrollSelectedItem()
        return false
      } else if (evt.keyCode === 40 && this.selectedItem < this.results.length) {
        this.selectedItem++
        this.scrollSelectedItem()
        return false
      } else if (evt.keyCode === 13) {
        if (this.selectedItem >= 0 && this.selectedItem < this.results.length) {
          this.navigate(this.results[this.selectedItem])
        }
      }
    },
    scrollSelectedItem () {
      this.$nextTick(() => {
        const el = document.querySelector('.t-search-item.selected')
        if (el) {
          const box = document.querySelector('.t-search-drop')
          if (box) {
            this.scrollParentToChild(box, el)
          }
        }
      })
    },
    scrollParentToChild (parent, child) {
      var parentRect = parent.getBoundingClientRect()
      var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
      }
      var childRect = child.getBoundingClientRect()
      var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height)
      if (!isViewable) {
        const scrollTop = childRect.top - parentRect.top
        const scrollBot = childRect.bottom - parentRect.bottom
        if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
          parent.scrollTop += scrollTop
        } else {
          parent.scrollTop += scrollBot
        }
      }
    },
    handleForm (evt) {
      evt.preventDefault()
      return false
    },
    handleClear() {
      this.msearch = ''
      this.open = false
      this.$emit('clear')
    },
    async handleSearchChanged () {
      if (this.msearch.length > 1) {
        this.bufferSearchChanged()
      } else {
        this.results = []
      }
    },
    sendEventToGoogle () {
      const url = window.location.pathname
      gtag('event', 'search', {
        event_category: url,
        event_label: this.msearch
      })
    },
    handleSearch () {
      if (this.selectedItem >= 0) {
        this.navigate(this.results[this.selectedItem])
        return
      }
      this.focused = false
      if (this.msearch.length < 1) {
        return
      }
      
      this.open = false
      this.$refs.searchy.blur()
      this.msearch = this.msearch.trim()
      if (this.msearch === '') {
        return
      }
      if(!this.msfilter) {
        this.searchNavigate()
        return
      }
      // will be handled by parent
      this.$emit('search', this.msearch)
    },
    searchNavigate () {
      let url = '/search?search=' + encodeURIComponent(this.msearch)
      if (this.mode) {
        url += '&mode=' + encodeURIComponent(this.mode)
      }
      this.sendEventToGoogle()
      if (this.$router) {
        this.$router.push(url)
      } else {
        window.location = url
      }
    },
    async bufferSearchChanged () {
      this.selectedItem = -1
      window.clearTimeout(this.tt)
      this.tt = window.setTimeout(async () => {
        let res = []
        if(this.msfilter) {
          res = await post('GetAutoSuggestTitlesMs', { search: this.msearch, id: this.metasection.id })
        }
        else {
          res = await post('GetAutoSuggestTitles', { search: this.msearch, mode: this.mode })
        } 
        this.createResults(res)
      }, 400)
    },
    createResults (rows) {
      this.results = []
      let ctr = 0
      // filter out duplicates
      let dupes = []
      for (const row of rows) {
        let murl = this.massageUrl(row)
        if(!dupes.includes(murl)){
          this.results.push(row)
          ctr++
          dupes.push(murl)
        }
        
      }
      if (ctr > 0) {
        this.openDrawer()
      }
      else {
        this.open = false
      }
    },
    massageUrl(article) {
      let rv = '/article/' + article.Id
      // but we do not have the sectionRef here probably,
      // so we should redirect from the article page to solve the problem
      if(article.SectionRef === 'help') {
        rv = '/help/article/' + article.Id
      }
      else if(article.SectionRef === 'annual-report') {
        rv = '/closeup/' + article.ParentUrl + '/article/' + article.Id
      }
      else if(article.SectionRef === 'getting-started') {
        rv = '/getting-started/article/' + article.Id
      }
      if(article.UrlRedir) {
        if(article.UrlRedir && (article.UrlRedir.startsWith('/') || article.UrlRedir.startsWith('http')))
        {
          rv = article.UrlRedir
          if(rv.startsWith('/article.aspx')) {
            rv = rv.substring(1)
          }
          if(rv.startsWith("article.aspx")){
            const parms = new URL('https://contributors.gettyimages.com/' + rv).searchParams
            if(parms.get("article_id")) {
              rv = '/article/' + parms.get('article_id')
            }
          }
        }
        
      }
      else if(article.Url) {
        if(article.Url.startsWith('/')) {
          rv = article.Url
        }
      }
      return rv
    },

    navigate (article) {
      this.$refs.searchy.blur()
      this.offClickHandler()
      this.open = false
      let murl = this.massageUrl(article)
      this.$nextTick(() => {
        //const wh = murl.indexOf('/', 1)
        //murl = murl.substring(wh)
        if (this.$router) {
          this.$router.push(murl)
        } else {
          window.location = murl
        }
      })
    },
    offClickHandler () {
      this.open = false
      document.removeEventListener('click', this.offClickHandler)
    },
    openDrawer () {
      if (!this.open) {
        this.open = true
        document.addEventListener('click', this.offClickHandler)
      }
    },
    handleOpen () {
      this.focused = true
      if (this.results.length > 0) {
        this.openDrawer()
      }
    },
    handleChangeMode () {
      if (this.$router) {
        if (this.$route.path.startsWith('/search')) {
          this.handleSearch()
        }
      }
    }
  },
  watch: {
    msearch () {
      this.handleSearchChanged()
    },
    mode () {
      this.handleSearchChanged()
    },
    '$route' () {
      this.open = false
    },
    focused () {
      this.filteropen = true
    }
  }
}
</script>

<style>

.t-search-holder {
  margin:10px 0 10px 10px;
  position:relative;
}

.t-search-holder.msfilter{
  margin:2px 0 2px 2px;
}

.middle-content .t-search-holder, .article-nav .t-search-holder{
  top:-110px;
}

.t-search-area {
  display:flex;
  align-items: center;
  height:34px;
  box-sizing:border-box;
  position:relative;
}



.t-search-area input[type="text"] {
  padding:7px;
  height:34px;
  box-sizing:border-box;
  width:100%;
  border: none;
  outline:none;
  display:block;
  margin:0;
  font-size:14px;
  border-radius:4px;
}

/*
.t-search-area.focused{
  box-shadow:0 0 3px rgba(0,0,0,0.3);
}
*/

.search-glass{
  display:inline-block;
  width:34px;
  height:34px;
  box-sizing: border-box;
  position:relative;
  cursor:pointer;
  transition:all 0.2s linear;
  position:relative;
}

.search-glass svg:not(:root) {
  position:absolute;
  width:20px;
  height:20px;
  top:50%;
  left:50%;
  z-index:10;
  transform:translate(-50%,-50%);
}

.t-search-area:hover .search-glass{
  color:var(--ccw-hilite);
}

.t-search-grower{
  flex-grow:1;
  position:relative;
}

.t-search-drop {
  position:absolute;
  top:34px;
  left:34px;
  width:calc(100% - 34px);
  box-sizing: border-box;
  overflow-x:hidden;
  overflow-y:auto;
  z-index:600;
  background-color:#fff;
  box-shadow:3px 3px 4px rgba(0,0,0,0.4);
  border-left:1px solid #000;
  border-right:1px solid #000;
  border-top:1px solid #000;
  opacity:0;
  pointer-events: none;
  max-height:calc(100vh - 200px);
  overflow-y:auto;
}

.t-search-drop.viz{
  opacity:1;
  pointer-events: all;
}

.t-search-item{
  padding:8px;
  border-bottom:1px solid #000;
  cursor:pointer;
  text-align: left;
}

.t-search-item.selected {
  background-color:#000;
  color:#fff;
}

.t-search-item:hover{
  background-color:#eee;
}

.t-search-filter{
  margin:8px 6px;
}

.t-search-filter label{
  font-weight:bold;
  margin-right:8px;
}


.search-clear {
  position: absolute;
  z-index: 10;
  right:5px;
  top: 50%;
  transform:translate(0, -50%);
  opacity:0;
  border:1px solid #000;
  padding:1px 5px;
  border-radius:3px;
  font-size:12px;
  cursor:pointer;

}

.search-clear.viz {
  opacity:0.3;
}

.search-clear.viz:hover {
  opacity:1;
}

</style>
