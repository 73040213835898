<template>
  <div class="obscura" v-if="showing" v-on:click="close" :class="{ visible: visible }">
    <div class="vjms-dialog" v-on:click.stop :class="{ scrolling: scrollable }">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { watch, nextTick, ref } from 'vue'
import { waitfor } from '@/utils.js'
const model = defineModel({ type: Boolean })
defineProps({ scrollable: Boolean })
const emit = defineEmits(['close'])

const visible = ref(false)
const showing = ref(false)

const close = () => {
  model.value = false
  emit('close', model.value)
}

async function showOpenAnimation() {
  console.log('doing show')
  showing.value = true
  await nextTick()
  await waitfor(100)
  visible.value = true
  document.addEventListener('keydown', handleKeyDown)
}

function handleKeyDown(e) {
  if (e.key === 'Escape') {
    close()
  }
}

async function showCloseAnimation() {
  document.removeEventListener('keydown', handleKeyDown)
  visible.value = false
  await waitfor(300)
  showing.value = false
}

watch(model, () => {
  console.log('got model', model)
  if (model.value) {
    showOpenAnimation()
  } else {
    showCloseAnimation()
  }
})
</script>

<style scoped>
.obscura {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.obscura.visible {
  opacity: 1;
}

.vjms-dialog {
  display: inline-block;
  min-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  z-index: 1510;
  max-width: 95vw;
  max-height: 95vh;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.3);
  box-sizing: border-box;
  transition: all 0.2s linear;
  opacity: 0;
}

.visible .vjms-dialog {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.vjms-dialog.scrolling {
  overflow-y: auto;
}

.vjms-dialog:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
}

.vjms-dialog h2 {
  margin: -20px -20px 20px -20px;
  background-color: var(--ccw2-bg);
  color: #000;
  padding: 20px;
  font-size: 18px;
  font-weight: normal;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
