<template>
  <div class="ms-subnav">
    <div v-for="(section, index) in sections" :class="{sel: isSelected(section, index)}" @click="navigateToSection(section)" :key="section.id">
      {{ section.name }}
    </div>
  </div>
</template>
<script>
export default {
  inject: ['store'],
  props: {
    sections: Array
  },
  computed: {
    currentSection () {
      return this.store.state.currentSection
    },
    sections () {
      if(this.store.state.currentMetasection) {
        return this.store.state.currentMetasection.sections
      }
    },
    sectionUrl () {
      return `${this.store.state.currentMetasection.url}/${this.currentSection.urlname}`
    }
  },
  methods: {
    isSelected (section, index) {
      if(this.$route.path === this.store.state.currentMetasection.url) {
        return (index === 0)
      }
      const su = `${this.store.state.currentMetasection.url}/${section.urlname}`
      if(this.$route.path === su){
        return true
      }
      return false
    },
    navigateToSection (section) {
      const url = `${this.store.state.currentMetasection.url}/${section.urlname}`
      if(this.$route.path !== url){
        this.$router.push(url)
      }
    }
  }
}

</script>