<template>
  <div class="ccw-site-holder">
    <div class="ccw-nav" :class="{ open: showNav }">
      <metasection-nav @close="handleClose" />
    </div>
    <div class="ccw-content">
      <header class="ccw-main" :class="{ open: showNav }">
        <div class="ccw-getty-bar">
          <div class="ccw-mini-bar">
            <div>
              <a href="https://esp.gettyimages.com/">{{ $xtrans.upload }}</a>
            </div>
            <div>
              <a href="https://esp.gettyimages.com/sign-in?returnUrl=/acm">{{ $xtrans.acct_management }}</a>
            </div>
            <div class="current-site">{{ $xtrans.contributor_community }}</div>
          </div>
        </div>
        <div class="tri-set">
          <div class="tri-chunk">
            <div class="center-all nav-icon" @click="toggleNav">
              <icon-hamburger-vue></icon-hamburger-vue>
            </div>
            <div class="ccw-title">
              <router-link to="/home">{{ $xtrans.webtitle }}</router-link>
            </div>
          </div>
          <div class="site-logo">
            <router-link to="/home"><img src="./assets/getty-istock.png" alt="gettyimages | iStock" /></router-link>
          </div>
          <div class="tri-set">
            <a class="icon-button" href="https://esp.gettyimages.com/contribute/briefs" target="_blank" v-show="logged"
              ><icon-briefs></icon-briefs>
              <div>{{ $xtrans.briefs }}</div></a
            >
            <language-menu></language-menu>
            <user-menu :name="member.FirstName"></user-menu>
            <a class="icon-button" href="https://esp.gettyimages.com/ccw" v-if="!member.Id">{{ $xtrans.sign_in }}</a>
          </div>
        </div>
      </header>
      <div class="ccw2-search-area" v-show="!insider">
        <div class="search-panel" v-if="logged">
          <search-area />
        </div>
      </div>
      <SectionSubnav v-if="showSubnav"></SectionSubnav>
      <RouterView />
    </div>
    <PopupWindow :jpop="jpop"></PopupWindow>
    <modal-dialog v-model="mshow">
      <div class="alert-dialog">
        <div>
          <div class="preline" v-html="alertMessage"></div>
        </div>
        <div class="button-panel">
          <tiny-button big round color="var(--ccw-hilite)" @click="mshow = false">OK</tiny-button>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>
<script>
import { provide } from 'vue'
import MetasectionNav from './components/nav/MetasectionNav.vue'
import IconHamburgerVue from './components/icons/IconHamburger.vue'
import SearchArea from './components/search/SearchArea.vue'
import IconBriefs from './components/icons/IconBriefs.vue'
import UserMenu from './components/UserMenu.vue'
import LanguageMenu from './components/LanguageMenu.vue'
import SectionSubnav from './components/SectionSubnav.vue'
import PopupWindow from './components/PopupWindow.vue'
import { post, waitfor } from './utils.js'
import ModalDialog from '@/components/dialog/ModalDialog.vue'
import TinyButton from '@/components/widgets/TinyButton.vue'

export default {
  inject: ['store'],
  components: {
    SearchArea,
    IconHamburgerVue,
    MetasectionNav,
    IconBriefs,
    UserMenu,
    LanguageMenu,
    SectionSubnav,
    PopupWindow,
    ModalDialog,
    TinyButton
  },
  data() {
    return {
      showNav: false,
      jpop: null,
      alertMessage: '',
      mshow: false
    }
  },
  created() {
    this.init()
    provide('showAlert', this.showAlert)
  },
  mounted() {
    this.checkForPopup()
  },
  computed: {
    insider() {
      return this.store.insider
    },
    member() {
      return this.store.member
    },
    logged() {
      return this.store.logged
    },
    memberName() {
      if (this.member.FirstName) {
        return this.member.FirstName + ' ' + this.member.LastName
      }
      return this.member.LastName
    },
    showSubnav() {
      if (this.$route.path.startsWith('/review-menu')) {
        return false
      }
      if (this.store.state.currentSection) {
        return this.store.state.currentSection.layout2 === 'tabs'
      }
      return false
    },
    currentSection() {
      return this.store.state.currentSection
    },
    metasection() {
      return this.store.state.currentMetasection
    }
  },
  methods: {
    showAlert(message) {
      console.log('Doing showAlert in App.vue', message)
      this.alertMessage = message
      this.mshow = true
    },
    async checkForPopup() {
      await waitfor(1000)
      if (!this.logged) {
        return
      }
      const popwin = await post('GetCurrentPopup')
      if (popwin === null) {
        this.jpop = null
        return
      }
      this.jpop = popwin
    },
    async init() {
      const res = await post('GetMember')
      this.store.setMember(res)
      this.store.loadMetasections()
    },
    toggleNav() {
      this.showNav = !this.showNav
    },
    handleClose() {
      this.showNav = false
    }
  }
}
</script>
<style scoped>
.alert-dialog {
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 12px;
}

.alert-dialog .button-panel,
.imdialog-panel .button-panel {
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: right;
  border-top: 1px solid var(--ccw-hilite);
  padding-top: 10px;
}
.preline {
  white-space: pre-line;
}
</style>
<style>
.ccw-main {
  width: 100%;
  position: fixed;
  z-index: 30;
  transition: all 0.3s ease-in-out;
}

.ccw-main a {
  text-decoration: none;
}

.ccw-getty-bar {
  background-color: #000;
  height: 32px;
  color: #fff;
  place-content: end;
  place-items: center;
  display: flex;
  font-size: 13px;
  padding: 0 22px;
}
.ccw-mini-bar {
  display: flex;
  place-items: center;
  place-content: center;
  column-gap: 16px;
}

.ccw-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ccw-site-holder {
  display: flex;
}

.ccw-nav {
  width: 0;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0;
  z-index: 10;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

.ccw-nav.open {
  width: 300px;
  border-right: 1px solid #aaa;
  flex-shrink: 0;
}

.ccw-mini-bar a {
  color: #fff;
}

.ccw-mini-bar a:visited {
  color: #fff;
}

.ccw-mini-bar a:hover {
  color: var(--ccw-hilite);
  text-decoration: none;
}

.ccw-mini-bar .current-site {
  color: #a1a0a0;
}

.center-all {
  display: flex;
  place-items: center;
  place-content: center;
  cursor: pointer;
}

header.ccw-main {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.tri-set {
  display: flex;
  place-content: space-between;
  place-items: center;
  height: 64px;
  padding: 0 20px;
  position: relative;
}

.tri-set .site-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tri-chunk {
  display: flex;
  place-items: center;
}

.tri-chunk > div {
  margin-right: 20px;
}

.ccw-title {
  font-size: 22px;
}

.ccw-title a,
.ccw-title a:visited {
  color: var(--color-text);
}

.ccw-title a:hover {
  color: var(--ccw-hilite);
  text-decoration: none;
}

a.icon-button,
a.icon-button:visited {
  display: flex;
  color: #000;
  place-items: center;
}

a.icon-button:hover {
  text-decoration: none;
  color: var(--ccw-hilite);
}

.nav-icon {
  width: 30px;
  height: 30px;
  transition: all 0.1s linear;
}

.nav-icon:hover {
  transform: scale(1.2);
}

.nav-icon:active {
  transform: scale(1.4);
}

.icon-button {
  background-size: 18px;
  background-position: left center;
  background-repeat: no-repeat;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.08s linear;
  font-size: 14px;
  position: relative;
  margin-left: 10px;
  user-select: none;
}

.icon-button svg:not(:root) {
  height: 20px;
  width: 20px;
}

.icon-button > div {
  margin-left: 4px;
}

.icon-button:hover {
  color: var(--ccw-hilite);
  transform: scale(1.1);
}

.icon-button:active {
  color: var(--ccw-hilite);
  transform: scale(1.2);
}

.icon-button.briefs:hover {
  background-image: url('./assets/briefs-over.svg');
}

.icon-button.language {
  background-image: url('./assets/language.svg');
}

.icon-button.language:hover {
  background-image: url('./assets/language-over.svg');
}

.icon-button.info {
  background-image: url('./assets/info.svg');
}

.icon-button.info:hover {
  background-image: url('./assets/info-over.svg');
}

.icon-button.user {
  background-image: url('./assets/user.svg');
}

.icon-button.user:hover {
  background-image: url('./assets/user-over.svg');
}

.ccw2-search-area {
  padding: 30px;
  background-color: var(--ccw-bar-green);
  color: #000;
  text-align: center;
  margin-top: 95px;
}

.site-name {
  text-align: center;
  font-size: 30px;
}

.search-panel {
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #000;
  padding: 3px 4px;
  width: 50%;
  max-width: 700px;
  min-width: 290px;
  display: inline-block;
  background-color: #fff;
}

.ms-subnav {
  display: flex;
  justify-content: center;
  background-color: var(--ccw-bar-green);
  overflow: hidden;
}

.ms-subnav > div {
  padding: 10px 60px;
  background-color: #fff;
  color: #000;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 18px;
  opacity: 0.5;
}

.ms-subnav > div:hover {
  background-color: #fff;
  color: #000;
  z-index: 20;
  opacity: 1;
}

.ms-subnav > div.sel {
  background-color: #fff;
  color: #000;
  z-index: 10;
  opacity: 1;
}

.content-area {
  display: flex;
}

/* hide search bar for insider/closeup */
.insider-report .ccw2-search-area {
  display: none;
}

@media screen and (min-width: 1024px) {
  .ccw-main.open {
    width: calc(100% - 300px);
  }
}

@media screen and (max-width: 1400px) {
  .tri-set .site-logo {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .ccw-nav {
    position: fixed;
    z-index: 100;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  }
}

@media screen and (max-width: 768px) {
  .ccw-title {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .tri-set {
    padding-right: 0;
  }
  .ccw-getty-bar {
    padding: 0;
    place-content: center;
  }
  .ccw-mini-bar {
    column-gap: 9px;
  }
}
</style>
