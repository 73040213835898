<template>
  <div class="language-menu">
    <a class="icon-button" @click="showMenu = true"><icon-language></icon-language><div class="nobr">{{ selectedLanguage }}</div></a>
    <speech-bubble v-model="showMenu">
      <div v-for="lang in langs" class="lang-item" @click="handleChangeLanguage(lang)" :class="{ sel: lang.Code === selected }">
        {{ lang.Description }}
      </div>
    </speech-bubble>
  </div>
</template>
<script>
  import SpeechBubble from './SpeechBubble.vue'
  import IconLanguage from './icons/IconLanguage.vue'
  import { post } from '../utils.js'

  export default {
    inject: ['store'],
    components: {
      SpeechBubble,
      IconLanguage
    },
    data () {
      return {
        showMenu: false,
        langs: [],
        selected: 'en-us'
      }
    },
    computed: {
      selectedLanguage ()  {
        if(this.langs.length < 1) {
          return ''
        }
        let res = this.langs.find(el => el.Code === this.selected)
        if(res) {
          return res.Description
        }
        return 'Language'
      },
      storeLanguage () {
        return this.$store.language
      }
    },
    created () {
      this.loadData()
    },
    methods: {
      async loadData () {
        this.loadLangs()
        this.loadSelected()
        
      },
      async loadLangs() {
        this.langs = await post('GetLanguages')
      },
      async loadSelected() {
        this.selected = await post('GetLanguageCode')
        this.store.setLocalized(this.selected)

      },
      async handleChangeLanguage (lang) {
        if(lang.Code === this.selected) {
          return
        }
      
        if(this.$route.query && this.$route.query.lang) {
          const q = Object.assign({}, this.$route.query)
          delete q.lang
          this.$router.replace({ query: q })
        }
        await post('ChangeLanguage', { code: lang.Code})
        this.selected = lang.Code
        this.store.setLocalized(this.selected)

        this.$router.go()
      }
    },
    watch: {
      storeLanguage () {
        this.selectedLanguage = this.storeLanguage
      }
    }
    
  }
</script>
<style>
.language-menu {
  position: relative;
}

.language-menu .nobr {
  white-space: nowrap;
}

.lang-item {
  cursor:pointer;
  white-space: nowrap;
}
.lang-item:hover{
  color:var(--ccw-hilite);
}

.lang-item.sel {
  color:#666;
  cursor: default;
}
</style>