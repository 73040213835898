import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { post, get, goSignIn, waitfor } from './utils.js'

import "./assets/main.css";
import store from './global.js'
import { getFromSectionCache, addToSectionCache } from "./utils";

const app = createApp(App);
app.config.globalProperties.$getFromSectionCache = getFromSectionCache;
app.config.globalProperties.$addToSectionCache = addToSectionCache;
app.config.globalProperties.$post = post
app.config.globalProperties.$get = get
app.config.globalProperties.$store = store
app.config.globalProperties.$xtrans = window.xtrans
app.config.globalProperties.$signin = goSignIn
app.use(router);
app.provide('store', store)
app.provide('waitfor', waitfor)
app.mount("#app");

console.log('ccw v1.3')
