import { reactive, readonly } from 'vue'
import { post, waitfor } from './utils.js'

let loadingMetasections = false
let initialized = false

const state = reactive({
  member: {},
  language: 'en-us',
  logged: false,
  metasections: [],
  currentSection: null,
  currentMetasection: null,
  insider: false
})

const setMember = (mem) => {
  if (mem) {
    state.member = mem
    state.logged = true
  }
  else {
    console.log('did not get a member')
    state.member = {}
    state.logged = false
  }
}


const setMetasections = (ms) => {
  state.metasections = ms
}

const setLocalized = async (lang) => {
  try {
    console.log('would be loading', lang + '.json')
    //state.localized = await import('/localization/' + lang + '.json')
    // must load from public files i.e. outside of vue assets
  }
  catch (err) {
    console.log(err)
    //state.localized = await import('/localization/en-us.json')
  }
}

export default {
  state: readonly(state),
  get metasections() {
    return state.metasections
  },
  get member() {
    return state.member
  },
  get logged() {
    return state.logged
  },
  get showTabs() {
    return state.showTabs
  },
  get localized() {
    return state.localizeed
  },
  get language() {
    return state.language
  },
  get insider() {
    return state.insider
  },
  setInsider(val) {
    state.insider = val
  },
  setLanguage(lang) {
    state.language = lang
  },
  async loadMetasections() {
    //console.log('called loadMetasections, loading is', loadingMetasections)
    let wt = 0
    if (loadingMetasections) {
      for (let i = 0; i < 12; i++) {
        await waitfor(300)
        wt += 300
        if (!loadingMetasections) {
          //console.log('breaking after', wt, 'ms because loadingMetasections is now false')
          break
        }
      }
      //console.log('completed for loop after', wt, 'ms')
    }
    if (state.metasections.length) {
      return state.metasections
    }
    loadingMetasections = true
    state.metasections = await post('GetConcertinaMetasections')
    loadingMetasections = false
    return state.metasections
  },
  async reloadMetasections() {
    state.metasections = await post('GetConcertinaMetasections')
  },
  async getMetasectionByUrl(url) {
    await this.loadMetasections()
    const murl = '/' + url
    const ms = state.metasections.filter(el => el.url === murl)
    if (ms.length > 0) {
      return ms[0]
    }
    return {}
  },
  findSectionByUrlName(sname, metasection) {
    if (!metasection) {
      return null
    }
    if (!metasection.sections) {
      return null
    }
    for (const section of metasection.sections) {
      if (section.urlname === sname) {
        return section
      }
    }
    return null
  },
  findSectionById(sid) {
    for (const ms of state.metasections) {
      for (const s of ms.sections) {
        if (s.id === sid) {
          return s
        }
      }
    }
    return null
  },
  getInitialized() {
    return initialized
  },
  setInitialized(val) {
    initialized = val
  },
  async setCurrentSection(sid) {
    try {
      await this.loadMetasections()
      for (const ms of state.metasections) {
        for (const s of ms.sections) {
          if (s.id === sid) {
            state.currentSection = s
            state.currentMetasection = ms
            return
          }
        }
      }
      state.currentSection = null
      state.currentMetasection = null
    }
    catch (err) {
      console.log('unable to set section to', sid, err)
    }
  },
  setMember,
  setMetasections,
  setLocalized
}