<template>
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.17 18.17">
    <g id="Layer_1-2">
      <g>
        <circle class="lang-2" cx="9.09" cy="9.09" r="8.59" />
        <path class="lang-2"
          d="M3.46,15.57c1.51,1.31,3.47,2.1,5.63,2.1s4.12-.79,5.63-2.1c-1.51-1.31-3.47-2.1-5.63-2.1s-4.12,.79-5.63,2.1Z" />
        <path class="lang-2"
          d="M3.46,2.6c1.51,1.31,3.47,2.1,5.63,2.1s4.12-.79,5.63-2.1c-1.51-1.31-3.47-2.1-5.63-2.1S4.97,1.29,3.46,2.6Z" />
        <line class="lang-2" x1=".5" y1="9.09" x2="17.66" y2="9.09" />
        <line class="lang-2" x1="9" y1="17.59" x2="9" y2=".42" />
        <ellipse class="lang-1" cx="9.09" cy="9.09" rx="4.77" ry="8.5" />
      </g>
    </g>
  </svg>
</template>
<style>
  .lang-1 {
    fill: none;
  }

  .lang-1,
  .lang-2 {
    stroke: currentColor;
    stroke-miterlimit: 10;
  }

  .lang-2 {
    fill: #fff;
  }
</style>