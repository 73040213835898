<template>
  <div class="reward-panel" v-show="store.member.Exclusive" @click="goRewards()">
    <div class="rp-pts-top">
      {{ points }} {{ brief_pts }}
    </div>
    <div
      class="rp-graphic"
      :class="'rp-' + store.member.RewardLevel"
    />
    <div
      class="rp-pts small"
      v-if="store.member.RewardLevel"
    >
      {{ rwd_prg_lvl }} {{ store.member.RewardLevel }}
    </div>
    <div
      class="rp-pts small"
      v-if="!store.member.RewardLevel"
    >
      {{ rwd_prg }}
    </div>
  </div>
</template>

<script>

export default {
  inject: ['store'],
  data () {
    return {
      brief_pts: window.xtrans.brief_pts,
      rwd_prg_lvl: window.xtrans.rwd_prg_lvl,
      rwd_prg: window.xtrans.rwd_prg
    }
  },
  computed: {
    points () {
      if(this.store.member) {
        return this.store.member.CcTotalDownloads + this.store.member.CreativeSubmissions + this.store.member.CustomSubmissions
      }
      return 0
    }
  },
  methods: {
    goRewards () {
      if (this.$router) {
        if (this.$route.path !== '/rewards/my-rewards') {
          this.$router.push('/rewards/my-rewards')
        }
      } else {
        window.location = '/rewards/my-rewards/'
      }
    }
  }
}
</script>

<style>
.reward-panel{
  width:100%;
  background-color:#e7f4f2;
  box-sizing:border-box;
  padding:8px;
  margin-top:20px;
  cursor:pointer;
}

.reward-panel:hover{
  box-shadow: 0 0 6px #9eccc6;
}

.reward-panel .rp-graphic{
  width:100%;
  height:26px;
  background-repeat:no-repeat;
  background-size: contain;
  background-position:center;
}

.rp-pts.small{
  font-size:13px;
}
.rp-pts-top{
  font-weight:bold;
}

.reward-panel .rp-graphic.rp-0{
  background-image:url('../assets/rp-0.svg');
}
.reward-panel .rp-graphic.rp-1{
  background-image:url('../assets/rp-1.svg');
}
.reward-panel .rp-graphic.rp-2{
  background-image:url('../assets/rp-2.svg');
}
.reward-panel .rp-graphic.rp-3{
  background-image:url('../assets/rp-3.svg');
}
</style>
