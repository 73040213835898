import { createRouter, createWebHistory } from "vue-router";
import { waitfor, post } from '@/utils.js'
import store from '@/global.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          })
        }, 500)
      })
    }
    else {
      return { top: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/test-page',
      name: 'test page',
      component: () => import('../views/TestPage.vue')
    },
    {
      path: '/Welcome.aspx',
      redirect: '/home'
    },
    {
      path: '/discord-verify',
      name: 'discord verify',
      component: () => import('../views/discord/DiscordVerify.vue')
    },
    {
      path: '/search',
      name: 'search results',
      component: () => import('../views/SearchResults.vue'),
      props: route => ({
        search: route.query.search,
        mode: route.query.mode,
        tag: route.query.tag
      })
    },
    {
      path: '/ai',
      name: 'ai section',
      component: () => import('../views/SectionHome.vue'),
      props: {
        metasection: 'creative-resources',
        section: 'ai'
      }
    },
    {
      path: '/creative-resources/ai',
      redirect: '/ai'
    },
    {
      path: '/show-us',
      name: 'project show us',
      component: () => import('../views/ArticleDisplay.vue'),
      props: {
        id: 9432
      }
    },
    {
      path: '/notaffiliate',
      name: 'toolkit affiliate page',
      component: () => import('../views/NotAffiliate.vue')
    },
    {
      path: '/SearchResults.aspx',
      redirect: () => {
        return { path: '/search' }
      }
    },
    {
      path: '/forum/:afterForum(.*)',
      name: 'Forum Holder',
      component: () => import('@/views/EmptyView.vue'),
      props: true
    },
    {
      path: '/toolbox/:afterToolbox(.*)',
      name: 'Toolbox Holder',
      component: () => import('@/views/ToolboxView.vue'),
      props: true
    },
    {
      path: '/custom-content',
      name: 'custom content home',
      component: () => import('@/components/lhnav-articles/LhNavArticle.vue'),
      alias: '/custom-content/',
      props: {
        sectionref: 'custom_content_beta',
        baseurl: '/custom-content',
        id: 0
      }
    },
    {
      path: '/notifications',
      name: 'manage email prefs',
      component: () => import('@/views/ManageSubscriptions.vue'),
      alias: ['/notifications/ManageSubscriptions.aspx', '/notifications/PublicSubscriptions.aspx']
    },
    {
      path: '/custom-content/article/:id',
      name: 'custom content article',
      component: () => import('@/components/lhnav-articles/LhNavArticle.vue'),
      props: route => ({
        sectionref: 'custom_content_beta',
        baseurl: '/custom-content',
        id: route.params.id
      })
    },
    {
      path: '/getting-started/',
      name: 'getting-started home',
      component: () => import('@/components/lhnav-articles/LhNavArticle.vue'),
      alias: '/getting-started',
      props: {
        sectionref: 'getting-started',
        baseurl: '/getting-started',
        id: 0
      }
    },
    {
      path: '/getting-started/article/:id',
      name: 'getting-started article',
      component: () => import('@/components/lhnav-articles/LhNavArticle.vue'),
      props: route => ({
        sectionref: 'getting-started',
        baseurl: '/getting-started',
        id: route.params.id
      })
    },
    {
      path: '/learn/article/:id',
      redirect: to => '/article/' + to.params.id
    },

    {
      path: '/rewards/article/:id',
      redirect: to => '/article/' + to.params.id
    },
    {
      path: '/article.aspx',
      redirect: to => '/article/' + to.query.article_id
    },
    {
      path: '/article_public.aspx',
      redirect: to => '/article/' + to.query.article_id
    },
    {
      path: '/article/:id',
      name: 'article page',
      component: () => import('../views/ArticleDisplay.vue'),
      props: true
    },
    {
      // redirect for old home page
      path: '/freelancer',
      name: 'Freelancer',
      component: () => import('../views/ArticleDisplay.vue'),
      props: {
        id: 8407
      }
    },
    {
      path: '/rewards',
      name: 'rewards meta',
      component: () => import('../views/RewardsHome.vue'),
      props: {
        metasection: 'rewards',
        section: 'rewards'
      },
      children: [
        {
          path: '/rewards',
          name: 'rewards home',
          component: () => import('../views/rewards/RewardsHomePage.vue'),
          alias: '/rewards/',
          props: {
            metasection: 'rewards',
            section: 'rewards'
          }
        },
        {
          path: '/rewards/my-rewards',
          name: 'my rewards',
          component: () => import('../views/rewards/MyRewards.vue'),
          props: {
            metasection: 'rewards',
            section: 'my-rewards'
          }
        }
      ]
    },
    {
      path: '/:metasection/:section',
      name: 'section home',
      component: () => import('../views/SectionHome.vue'),
      props: true
    },
    {
      path: '/:metasection',
      name: 'mshome',
      component: () => import('../views/MetaSectionHome.vue'),
      props: true
    },
    {
      path: '/help',
      name: 'help home',
      component: () => import('../views/HelpMain.vue')
    },
    {
      path: '/help/category/:id',
      name: 'help category',
      component: () => import('../views/HelpCategory.vue'),
      props: true
    },
    {
      path: '/HelpCategory.aspx',
      name: 'help cat redirect',
      redirect: to => '/help/category/' + to.query.cat_id
    },
    {
      path: '/help/article/:id',
      name: 'help article',
      component: () => import('../views/ArticleDisplay.vue'),
      props: (route) => ({ id: Number(route.params.id), help: true })
    },
    {
      path: '/HelpArticle.aspx',
      redirect: to => '/help/article/' + to.query.article_id
    },
    {
      path: '/review-menu',
      name: 'admin menu'
      // this is handled in beforeEach
    },
    {
      path: '/member-search/list',
      name: 'member search',
      component: () => import('../views/MemberSearch.vue')
    },
    {
      path: '/insider-report/:issue',
      name: 'insider old home',
      component: () => import('../insider-report/Home.vue'),
      props: true
    },
    {
      path: '/insider-report/:issue/article/:id',
      name: 'insider old article',
      component: () => import('../insider-report/InsiderArticle.vue'),
      props: (route) => ({ id: Number(route.params.id), issue: String(route.params.issue) })
    },
    {
      path: '/closeup/:issue',
      name: 'insider home',
      component: () => import('../insider-report/Home.vue'),
      props: true
    },
    {
      path: '/closeup/:issue/article/:id',
      name: 'insider article',
      component: () => import('../insider-report/InsiderArticle.vue'),
      props: (route) => ({ id: Number(route.params.id), issue: String(route.params.issue) })
    },
    {
      path: '/lgbtq-form',
      name: 'lgbtq form',
      component: () => import('../components/LgbtqForm.vue'),
      props: true
    },
    {
      path: '/logout',
      beforeEnter() {
        location.href = window.location.protocol + window.location.host + '/logout.aspx'
      }
    }
  ],
});

async function tagPage(path) {

  if (window.gtag) {
    //wait for title to be populated
    await waitfor(1000)
    window.gtag('config', 'G-3FEB5JR1CH', { 'page_path': path })
  }

}

async function changeLanguage(lang, next) {
  let res = await post('ChangeLanguage', { code: lang })
  if (res === 'ok') {

    store.setLocalized(lang)
    store.setLanguage(lang)
    store.reloadMetasections()
  }
  next()
}

router.beforeEach((to, from, next) => {
  if (to.name === 'admin menu') {
    window.location.href = '/admin/'
    return
  }
  if (to && to.query && to.query.lang) {
    changeLanguage(to.query.lang, next)
    return
  }
  if (from.path !== to.path) {
    tagPage(to.path)
  }
  next()
})

export default router;
