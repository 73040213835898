<template>
  <div class="user-menu">
    <div class="icon-div">
      <a class="icon-button" v-if="name" @click="showMenu = true"><icon-user></icon-user><div>{{ name }}</div></a>
      <speech-bubble v-model="showMenu">
        <div class="sp-menu" v-if="options">
          <div v-for="option in options">
            <template v-if="option.kind === 'external'">
              <a :href="option.url" class="nobr">{{ option.title }}</a>
            </template>
            <template v-else>
              <router-link :to="option.url" class="nobr">{{ option.title }}</router-link>
            </template>
          </div>
        </div>
      </speech-bubble>
    </div>
  </div>
</template>
<script>
  import IconUser from './icons/IconUser.vue'
  import SpeechBubble from './SpeechBubble.vue'
  export default {
    components: {
      SpeechBubble,
      IconUser
    },
    props: {
      name: String,
    },
    data () {
      return {
        showMenu: false,
        options: [ 
          {
            url: '/logout.aspx',
            title: 'Sign Out',
            kind: 'external'
          }
        ]
      }
    }
  }
</script>
<style>
.user-menu {
  position: relative;
}
</style>