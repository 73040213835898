<template>
  <div class="filter-switches" @click.stop>
    <div @click="toggleOptions" class="fs-drop-clicker">{{ options[selectedItem] }}</div>
    <div class="options"  :class="{dropped: showing}">
      <div :class="{selected: selectedItem === ''}" @click="select('')">{{  $xtrans.all }}</div>
      <div :class="{selected: selectedItem === 'F'}" @click="select('F')">{{ $xtrans.faqs_short }}</div>
      <div :class="{selected: selectedItem === 'A'}" @click="select('A')">{{ $xtrans.articles }}</div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      modelValue: String
    },
    data () {
      return {
        selectedItem: '',
        options: {
          '': this.$xtrans.all,
          'F': this.$xtrans.faqs_short,
          'A': this.$xtrans.articles
        },
        showing: false
      }
    },
    mounted () {
      document.body.addEventListener('click', this.closeOptions)
    },
    beforeUnmount () {
      document.body.removeEventListener('click', this.closeOptions)
    },
    methods: {
      select(val) {
        if(this.selectedItem !== val) {
          this.selectedItem = val
          this.$emit('update:modelValue', this.selectedItem)
          this.$emit('change', val)
        }
      },
      toggleOptions() {
        this.showing = !this.showing
      },
      showOptions() {
        this.showing = true
      },
      closeOptions () {
        this.showing = false
      }

    }
  }
</script>
<style>
  .filter-switches{
    position:relative;
    display: inline-flex;
    border-left: 1px solid #ccc;
    flex-direction:column;
    min-width:150px;
    padding:8px 16px;
  }
  
  .fs-drop-clicker {
    cursor:pointer;
    user-select: none;
  }

  .filter-switches .options{
    font-size:14px;
    position:absolute;
    height:0;
    top:40px;;
    left:0;
    width:100%;
    z-index:500;
    line-height:24px;
    background-color:#fff;
    overflow:hidden;
    transition:height 0.2s ease-in-out;
  }

  .filter-switches .options.dropped
  {
    height:100px;
    border:1px solid #ccc;
  }

  .filter-switches .options > div{
    background-color:#fff;
    margin: 0;
    padding:4px 12px;
    border-bottom:1px solid #ccc;
    z-index:10;
    cursor:pointer;
    user-select: none;
  }

  .filter-switches .options > div:hover{
    color:var(--ccw-hilite);
  }


  .filter-switches .options > div.selected {
    background-color:var(--ccw-hilite);
    color:#fff;
  }

  @media screen and (max-width: 650px) {
    .filter-switches {
      min-width: 80px;
    }
  }
</style>