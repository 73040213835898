<template>
  <div class="ccw-close-button" @click="$emit('click')" :class="{ small: small }">
    <icon-close></icon-close>
  </div>
</template>
<script>
import IconClose from '../icons/CloseIcon.vue'
export default {
  components: {
    IconClose
  },
  props: {
    small: Boolean
  }
}

</script>

<style>
  .ccw-close-button{
    width:18px;
    height:18px;
    position:absolute;
    top:7px;
    right:0px;
    cursor:pointer;
    transition: all 0.2s linear;
    z-index:20;
    color:#000;
  }

  .ccw-close-button.small {
    width: 16px;
    height: 16px;
    top: 16px;
    right: 16px;
  }

  .ccw-close-button:hover {
    transform: rotate(90deg);
    color:var(--ccw-hilite);
  }

  .ccw-close-button:active{
    transform:  rotate(90deg) scale(1.7);
  }
</style>