<template>
  <div class="speech-bubble-holder" :class="{open: open, opening: opening}" @click.stop>
    <div class="triangle"></div>
    <div class="speech-bubble">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  import { waitfor } from '../utils.js'

  export default {
    props: {
      modelValue: Boolean
    },
    emits: ['update:modelValue'],
    data () {
      return {
        opening: false,
        open: false
      }
    },
    methods: {
      async handleOpen () {
        this.opening = true
        await waitfor(50)
        this.open = true
        document.body.addEventListener('click', this.handleClose)
        this.$emit('update:modelValue', true)
      },
      async handleClose () {
        this.opening = false
        await waitfor(50)
        this.open = false
        document.body.removeEventListener('click', this.handleClose)
        this.$emit('update:modelValue', false)
      }
    },
    watch: {
      modelValue () {
        if(this.modelValue) {
          this.handleOpen()
        }
        else {
          this.handleClose()
        }
      }
    }
  }
</script>
<style>
  .speech-bubble-holder {
    position:absolute;
    top:calc(100% + 10px);
    left:50%;
    transform:translate(-50%, 0);
    z-index:20;
    display:none;
    opacity:0;
    transition:opacity 0.2s linear;
  }

  .speech-bubble-holder.opening {
    display:block;
  }

  .speech-bubble-holder.open {
    opacity:1;
  }

  .speech-bubble{
    background-color:#fff;
    border:1px solid #000;
    padding:10px;
    font-size:12px;
    
  }

  .speech-bubble a, .speech-bubble a:visited{
    color:#000;
  }

  .speech-bubble a:hover{
    color:var(--ccw-hilite);
    text-decoration: none;
  }

  .nobr{
    white-space: nowrap;
  }

  .triangle {
    width:14px;
    height:14px;
    border-top:1px solid #000;
    border-right:1px solid #000;
    position:absolute;
    background-color: #fff;
    z-index:20;
    top:-7px;
    left:50%;
    transform:translate(-50%, 0) rotate(-45deg);
  }
</style>