<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="30px"
    height="20px"
    viewBox="0 0 29.9639587 19.9589844"
    style="
      enable-background: new 0 0 30 20;
      enable-background: new 0 0 30 20;
    "
    xml:space="preserve"
    class="ham-icon"
    gi-icon="unisporkal/getty/hamburger"
    src="/landing/assets/static/hamburger_menu-334731bd2db7cbd51fe6.svg"
  >
    <g>
      <rect width="29.9639587" height="2.9716384"></rect>
      <rect y="8.7377119" width="29.9639587" height="2.9716384"></rect>
      <rect y="16.9873466" width="29.9639587" height="2.9716384"></rect>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.ham-icon {
  fill: black;
}
.ham-icon:hover {
  fill: var(--ccw-hilite);
}

</style>
